<template>
  <div v-if="consulta.id">
    <b-row>
      <b-col>
        <b-card :class="getClassByType()">
          <b-row align-v="center">
            <b-col sm="1">
              <div class="icone-header-page">
                <img :src="'/assets/images/modal_icons/' + getUrlImgByType()" />
              </div>
            </b-col>

            <b-col>
              <h1 class="titulo-header-page text-white">
                Consulta {{ getTypeConsulta() }} nº
                {{ consulta ? consulta.id : '' }}
              </h1>
              <!-- <h4 class="text-white">Inicie uma Consulta Inicial:</h4> -->
            </b-col>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-outline"
              @click="endAppointment()"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Concluir Consulta</span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-outline"
              @click="saveAppointment()"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Salvar Consulta</span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="button-right ml-1 botao-white-outline"
              :to="'/reagendarConsulta/' + consulta.id"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Reagendar</span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="button-right ml-1 botao-white-outline"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span class="align-middle">Excluir</span>
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- Geral -->

    <b-card>
      <b-row>
        <b-col lg="6">
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              size="70px"
              variant="light-danger"
              :text="getAbreviateByPatient(consulta.patient)"
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{
                    consulta ? consulta.patient.physicalPerson.firstName : ''
                  }}
                  {{ consulta ? consulta.patient.physicalPerson.lastName : '' }}
                </h4>
                <span class="card-text">
                  {{
                    consulta
                      ? consulta.patient.physicalPerson.person.email ||
                        'email@email.com'
                      : ''
                  }}</span
                >
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="'/paciente/' + consulta.patient.id"
                  variant="primary"
                >
                  Visualizar Prontuário
                </b-button>
              </div>
            </div>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="UserCheckIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ consulta.user.firstName || 'Sem Primeiro Nome' }}
                  {{ consulta.user.lastName || 'Sem Sobrenome' }}
                </h5>
                <small>Profissional</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="MapPinIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ consulta ? consulta.location || 'Sem localização' : '' }}
                </h5>
                <small>Localização</small>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-secondary" rounded>
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ getDay(consulta ? consulta.startDate : '') }}
                </h5>
                <small>Data da Consulta</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-secondary" rounded>
                <feather-icon icon="ClockIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ getHour(consulta ? consulta.startDate : '') }} às
                  {{ getHour(consulta ? consulta.endDate : '') }}
                </h5>
                <small>Duração da Consulta</small>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6">
          <h3>Observações da Consulta</h3>
          <b-form-textarea
            v-if="consulta"
            v-model="consulta.comments"
            id="textarea-rows"
            placeholder="Insira o texto aqui"
            rows="6"
          />

          <b-form-group>
            <h3 class="mt-2">Status do Paciente</h3>
            <v-select
              v-model="consulta.patient.physicalPerson.person.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allStatusPerson"
              label="title"
            >
              <template #option="{ title, icon }">
                <feather-icon :icon="icon" size="16" />
                <span v-bind:class="{ status1: 'status-ativo' }">
                  {{ title }}</span
                >
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!--- Card para execução de consulta -->
    <h2>
      <feather-icon icon="GridIcon" size="25" class="mr-30" /> Roteiro da
      Consulta
    </h2>
    <b-card>
      <b-row class="match-height">
        <b-col lg="4" md="6" v-if="isTestesCognitivo()">
          <b-card
            class="c-testes-cognitivos text-center"
            :class="{ aplicado: !isNullOrEmpty(consulta.appCognitiveTests) }"
          >
            <div class="check-corner">
              <feather-icon
                v-if="!isNullOrEmpty(consulta.appCognitiveTests)"
                icon="CheckCircleIcon"
                class="mr-50"
                size="40"
                color="white"
              />
            </div>
            <div class="img-consulta-sel">
              <img src="/assets/images/modal_icons/2_teste_cognitivos.png" />
            </div>
            <h3 class="txt-consulta-sel">Teste Cognitivo</h3>
            <b-button-group class="white-button-group">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="white-button"
                :to="'/NovoTesteCognitivo/' + consulta.id"
              >
                <feather-icon icon="PlayIcon" class="mr-50" />
                {{
                  !isNullOrEmpty(consulta.appCognitiveTests)
                    ? 'Reaplicar'
                    : 'Aplicar'
                }}
              </b-button>

              <b-button
                v-if="!isNullOrEmpty(consulta.appCognitiveTests)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="white-button"
                @click="excluir('appCognitiveTests')"
              >
                <feather-icon icon="TrashIcon" class="mr-50" /> Excluir
              </b-button>
            </b-button-group>
          </b-card>
        </b-col>

        <b-col lg="4" md="6" v-if="isJogoTeste()">
          <b-card
            class="c-jogo-teste text-center"
            :class="{ aplicado: gameTest != null }"
          >
            <div class="check-corner">
              <feather-icon
                v-if="gameTest != null"
                icon="CheckCircleIcon"
                class="mr-50"
                size="40"
                color="white"
              />
            </div>
            <div class="img-consulta-sel">
              <img src="/assets/images/modal_icons/3_jogo_teste.png" />
            </div>
            <h3 class="txt-consulta-sel">Jogo Teste</h3>
            <b-button-group class="white-button-group">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="white-button"
                :to="
                  '/jogos/3/' +
                  consulta.patient.id +
                  '/' +
                  consulta.id +
                  '/Sessao'
                "
              >
                <feather-icon icon="PlayIcon" class="mr-50" />
                {{ gameTest ? 'Reaplicar' : 'Aplicar' }}
              </b-button>

              <b-button
                v-if="gameTest"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="white-button"
              >
                <feather-icon icon="TrashIcon" class="mr-50" /> Excluir
              </b-button>
            </b-button-group>
          </b-card>
        </b-col>
        <b-col lg="4" md="6" v-if="isAplicacaojogo()">
          <b-card
            class="c-aplicacao-jogo text-center"
            :class="{ aplicado: game != null }"
          >
            <div class="check-corner">
              <feather-icon
                v-if="game != null"
                icon="CheckCircleIcon"
                class="mr-50"
                size="40"
                color="white"
              />
            </div>

            <div class="img-consulta-sel">
              <img src="/assets/images/modal_icons/5_aplicação do jogo.png" />
            </div>
            <h3 class="txt-consulta-sel">Aplicação do Jogo</h3>
            <b-button-group class="white-button-group">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="white-button"
                :to="
                  '/jogos/1/' +
                  consulta.patient.id +
                  '/' +
                  consulta.id +
                  '/Sessao'
                "
              >
                <feather-icon icon="PlayIcon" class="mr-50" />
                {{ game ? 'Reaplicar' : 'Aplicar' }}
              </b-button>

              <b-button
                v-if="game"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="white-button"
              >
                <feather-icon icon="TrashIcon" class="mr-50" /> Excluir
              </b-button>
            </b-button-group>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <template v-if="isConsultaInicial()">
      <!--- Card para consulta inicial -->
      <h2>
        <feather-icon icon="FileIcon" size="25" class="mr-30" /> Consulta
        Inicial
      </h2>
      <b-card>
        <b-card-text class="mb-2 mt-2">
          <h2 class="mb-2">Preencha o pontuário do paciente.</h2>
        </b-card-text>
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Demanda do paciente</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.initialConsultation.patientDemands"
          placeholder="Inserir Detalhes"
          rows="3"
        />
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Histórico do Distúrbio</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.initialConsultation.disturbanceHistory"
          placeholder="Inserir Detalhes"
          rows="3"
        />
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Antecedentes Pessoais e Familiares</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.initialConsultation.personalFamilyBg"
          placeholder="Inserir Detalhes"
          rows="3"
        />
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Informações Suplementares</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.initialConsultation.additionalInfo"
          placeholder="Inserir Detalhes"
          rows="3"
        />
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Medicações em Uso</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.initialConsultation.medicationsUse"
          placeholder="Inserir Detalhes"
          rows="3"
        />
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Observações</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.initialConsultation.comments"
          placeholder="Inserir Detalhes"
          rows="3"
        />
      </b-card>
    </template>
    <template v-if="isConsultaFinal()">
      <!--- Card para consulta final -->
      <h2>
        <feather-icon icon="FileIcon" size="25" class="mr-30" /> Consulta Final
      </h2>
      <b-card>
        <label class="mb-2 mt-2 col-form-label" for="textarea-default"
          >Devolutiva</label
        >
        <b-form-textarea
          id="textarea-default"
          v-model="consulta.comments"
          placeholder="Inserir Detalhes"
          rows="8"
        />
      </b-card>
    </template>

    <!--- Card anexos -->
    <h2>
      <feather-icon icon="FileIcon" size="25" class="mr-30" /> Anexos da
      Consulta
    </h2>
    <b-card>
      <DragAndDropComponent @update:files="files = $event" />
    </b-card>
  </div>
</template>


<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BButtonGroup,
  BFormTextarea,
  BFormGroup,
  BCardText,
} from 'bootstrap-vue'
import apexChatData from './apexChartData'
import vSelect from 'vue-select'
import medicalAppointmentService, {
  JOGO_ID,
  JOGO_TESTE_ID,
} from '@/services/medicalAppointmentService'
import DragAndDropComponent from '@/components/input/DragAndDrop.vue'
import * as moment from 'moment'
import { hideSpinner, showSpinner } from '@/services/spinService'
import authService from '@/services/authService'
import utilsService from '@/services/utilsService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import {
  allStatusPerson,
  getOneStatusPersonByName,
} from '@/@core/constants/personStatus'
import { AppointmentStatus, Games } from '@/constants/status'
import gameLevelService from '@/services/gameLevelService'

import Ripple from 'vue-ripple-directive'
moment.locale('pt-br')

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BFormGroup,
    BCardText,
    BButtonGroup,
    BFormTextarea,
    vSelect,
    DragAndDropComponent,
  },
  directives: {
    Ripple,
  },
  props: ['idConsulta'],
  data() {
    return {
      game: null,
      gameTest: null,
      files: [],
      allStatusPerson,
      consulta: {
        createdBy: null,
        createdAt: null,
        updatedBy: null,
        updatedAt: null,
        id: null,
        startDate: null,
        endDate: null,
        location: null,
        comments: null,
        status: null,
        number: null,
        patient: {
          createdBy: null,
          createdAt: null,
          updatedBy: null,
          updatedAt: null,
          id: null,
          age: null,
          birthDate: null,
          gender: null,
          nationality: null,
          occupation: null,
          maritalStatus: null,
          schooling: null,
          visitedPsy: true,
          outpatient: true,
          internment: true,
          note: null,
          parentNote: null,
          nationalHealthRegistry: null,
          physicalPerson: {
            createdBy: null,
            createdAt: null,
            updatedBy: null,
            updatedAt: null,
            id: null,
            cpf: null,
            professionalBoardNumber: null,
            firstName: null,
            lastName: null,
            person: {
              createdBy: null,
              createdAt: null,
              updatedBy: null,
              updatedAt: null,
              id: null,
              address: null,
              neighborhood: null,
              city: null,
              state: null,
              postalCode: null,
              phoneNumber: null,
              mobilePhone: null,
              email: null,
              status: null,
              login: null,
            },
          },
          dependents: null,
          parents: null,
        },
        user: {
          createdBy: null,
          createdAt: null,
          updatedBy: null,
          updatedAt: null,
          id: null,
          login: null,
          password: null,
          status: null,
          firstName: null,
          lastName: null,
          email: null,
          authorities: [],
          fullName: null,
        },
        initialConsultation: {},
        appCognitiveTests: null,
        consultationTypes: [],
      },
      selected: { title: 'Square' },
      selected1: {
        title: 'Aperture',
        icon: 'ApertureIcon',
        status1: 'status-ativo',
      },
      option: [
        { title: 'Square' },
        { title: 'Rectangle' },
        { title: 'Rombo' },
        { title: 'Romboid' },
      ],
      books: [
        {
          title: 'Ativo',
          icon: 'ApertureIcon',
          status1: 'status-ativo',
        },
        {
          title: 'Abandono',
          icon: 'CodepenIcon',
          status1: 'status-ativo',
        },
        {
          title: 'Alta',
          icon: 'GlobeIcon',
          status1: 'status-ativo',
        },
        {
          title: 'Óbito',
          icon: 'InstagramIcon',
          status1: 'status-ativo',
        },
      ],

      apexChatData,

      transactionData: [
        {
          mode: 'Formatação',
          types: 'Starbucks',
          avatar: 'HexagonIcon',
          avatarVariant: 'light-secondary',
          payment: 'Linha',
          deduction: false,
        },
        {
          mode: 'Áudio',
          types: 'Add Money',
          avatar: 'Volume2Icon',
          avatarVariant: 'light-secondary',
          payment: 'Ativado',
          deduction: false,
        },
        {
          mode: 'Tamanho da bola',
          types: 'Add Money',
          avatar: 'CircleIcon',
          avatarVariant: 'light-secondary',
          payment: '2',
          deduction: false,
        },
        {
          mode: 'Velocidade da Bola',
          types: 'Ordered Food',
          avatar: 'ChevronsRightIcon',
          avatarVariant: 'light-secondary',
          payment: '3',
          deduction: false,
        },
        {
          mode: 'Cor da Bola',
          types: 'Refund',
          avatar: 'TargetIcon',
          avatarVariant: 'light-secondary',
          payment: 'Saturada',
          deduction: false,
        },
      ],

      ModificadoresData: [
        {
          tipo: 'Positivo',
          icone: 'CheckSquareIcon',
          icone_style: 'light-secondary',
          nome: 'Aumento de Raquete',
          status: 'Ativo',
        },
        {
          tipo: 'Positivo',
          icone: 'CheckSquareIcon',
          icone_style: 'light-secondary',
          nome: 'Requete Grudenta',
          status: 'Ativo',
        },
        {
          tipo: 'Neutro',
          icone: 'SquareIcon',
          icone_style: 'light-secondary',
          nome: 'Múltiplas Bolas',
          status: 'Inativo',
        },
        {
          tipo: 'Neutro',
          icone: 'SquareIcon',
          icone_style: 'light-secondary',
          nome: 'Diminuição da Raquete',
          status: 'Inativo',
        },
        {
          tipo: 'Neutro',
          icone: 'SquareIcon',
          icone_style: 'light-secondary',
          nome: 'Raquete Congelada',
          status: 'Inativo',
        },
        {
          tipo: 'Neutro',
          icone: 'SquareIcon',
          icone_style: 'light-secondary',
          nome: 'Aumento de Raquete',
          status: 'Inativo',
        },
        {
          tipo: 'Neutro',
          icone: 'SquareIcon',
          icone_style: 'light-secondary',
          nome: 'Aumento de Raquete',
          status: 'Inativo',
        },
      ],
    }
  },
  methods: {
    ...utilsService,
    async verifyAndRedirect() {
      if (this.consulta.status == AppointmentStatus.CLOSE) {
        this.$router.push(`/resultado/${this.idConsulta}`)
      }

      hideSpinner()
    },
    async getFiles(){
      return medicalAppointmentService.getFile(this.idConsulta, 1)
        .then(response => {
          // this.downloadFile(response, 'file.pdf', 'application/pdf')
          // this.files = response.data
          // TODO- Implementar upload de arquivo
        })
    },
    async getConsulta() {
      await medicalAppointmentService.getById(this.idConsulta).then(res => {
        if (!res.initialConsultation) {
          res.initialConsultation = {}
        }
        if (!res.appCognitiveTests) {
          res.appCognitiveTests = {}
        }
        res.patient.physicalPerson.person.status = getOneStatusPersonByName(
          res.patient.physicalPerson.person.status
        )
        this.getFiles()
        this.consulta = res
        this.verifyAndRedirect()
      })
    },
    getDay(day) {
      return moment(day).format('LL')
    },
    getHour(hour) {
      return moment(hour).format('HH:mm')
    },
    prepareRequest(status) {
      let consultaJSON = JSON.parse(JSON.stringify(this.consulta))
      consultaJSON.patient.physicalPerson.person.status =
        consultaJSON.patient.physicalPerson.person.status.name
      consultaJSON = {
        ...consultaJSON,
        status: status || consultaJSON.status,
        login: authService.getLogin(),
        patientId: consultaJSON.patient.id,
        consultations: consultaJSON.consultationTypes.map(type =>
          this.getClearedObject({
            consultationTypeId: type.id,
            gameId: this.getGameId(type.id),
          })
        ),
      }
      return this.getClearedObject(consultaJSON)
    },

    getGameId(consultationTypeId) {
      if (consultationTypeId == 4) return JOGO_ID
      else if (consultationTypeId == 3) return JOGO_TESTE_ID
      else return null
    },
    async update(status) {
      showSpinner()
      medicalAppointmentService
        .update(this.prepareRequest(status))
        .then(async res => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Dados salvos com sucesso!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          hideSpinner()
          if (this.files.length) {
            await this.saveFiles()
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.verifyAndRedirect()
        })
    },
    async saveFiles() {
      const formData = new FormData()
      this.files.forEach(file => {
        formData.append('files', file)
      })
      showSpinner()
      medicalAppointmentService
        .saveFiles(this.consulta.id, 1, formData)
        .then(res => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Dados salvos com sucesso!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          hideSpinner()
        })
    },
    excluir(atributo) {
      this.consulta[atributo] = undefined
      console.log(this.consulta)
      this.update()
    },
    async reschedule() {
      this.update('OPEN')
    },
    async endAppointment() {
      this.update('CLOSE')
    },

    async saveAppointment() {
      this.update('REOPEN')
    },

    isConsultaInicial() {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes('Inicial')
      )
    },
    isConsultaFinal() {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes('Final')
      )
    },
    isTestesCognitivo() {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes('Testes')
      )
    },
    isJogoTeste() {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes('Jogo Teste')
      )
    },
    isAplicacaojogo() {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes('Aplicação')
      )
    },
    isConsultaRegular() {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes('Regular')
      )
    },
    hasTypeByLabel(label) {
      return this.consulta.consultationTypes.some(type =>
        type.name.includes(label)
      )
    },
    getTypeIdConsulta() {
      if (!this.consulta) return ''
      if (this.isConsultaInicial()) return 1
      if (this.isConsultaFinal()) return 6
      if (this.isConsultaRegular()) return 5
      return 0
    },
    getTypeConsulta() {
      if (!this.consulta) return ''
      if (this.isConsultaInicial()) return 'Inicial'
      if (this.isConsultaFinal()) return 'Final'
      if (this.isConsultaRegular()) return 'Regular'
      return ''
    },
    getClassByType() {
      switch (this.getTypeConsulta()) {
        case 'Inicial':
          return 'bg-gradient-primary'
        case 'Final':
          return 'gradient-cons-final'
        case 'Regular':
          return 'bg-gradient gradient-cons-regular'
        default:
          return ''
      }
    },
    getUrlImgByType() {
      switch (this.getTypeConsulta()) {
        case 'Inicial':
          return '1_consulta_inicial.png'
        case 'Final':
          return '4_devolutiva.png'
        case 'Regular':
          return '6_consulta_regular.png'
        default:
          return ''
      }
    },

    getGames() {
      gameLevelService
        .getGameLevelByGameAndAppointment(Games.KNAPDOWN, this.idConsulta)
        .then(res => {
          this.game = res.length ? res[0] : null
        })
      gameLevelService
        .getGameLevelByGameAndAppointment(Games.JOGO_TESTE, this.idConsulta)
        .then(res => {
          this.gameTest = res.length ? res[0] : null
        })
    },
  },
  async mounted() {
    if (this.idConsulta) {
      await this.getConsulta()
      await this.getGames()
    }
  },
}
</script>
