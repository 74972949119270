export const allStatusPerson = [
  {
    id: 1,
    name: 'ACTIVE',
    title: 'Ativo',
    icon: 'ApertureIcon',
  },
  {
    id: 2,
    name: 'INACTIVE',
    title: 'Inativo',
    icon: 'XIcon',
  },
  {
    id: 3,
    name: 'DELIVERED',
    title: 'Alta',
    icon: 'GlobeIcon',
  },
  {
    id: 4,
    name: 'ABANDONMENT',
    title: 'Abandono',
    icon: 'CodepenIcon',
  },
  {
    id: 5,
    name: 'DEATH',
    title: 'Óbito',
    icon: 'InstagramIcon',
  },
]

export const getOneStatusPersonById = statusId => allStatusPerson.find(statusEnum => statusEnum.id === statusId)

export const getOneStatusPersonByName = statusName => allStatusPerson.find(statusEnum => statusEnum.name === statusName)
